import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import apolsor from "../assets/apolsor.svg"
import '../App.css';
import carousel1 from "../assets/1.png"
import carousel2 from "../assets/2.png"
import carousel3 from "../assets/3.png"
import carousel4 from "../assets/4.png"
import ray1 from "../assets/ray1.png"
import ray2 from "../assets/ray2.png"
import ray3 from "../assets/ray3.png"
import splash from "../assets/splash.png"
import background from "../assets/background.png"

const Pricing = ({ image }) => {
  const mountRef = useRef(null);
  const [zoom, setZoom] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [pricingOn, setPricingOn] = useState(false)


  const materialRef = useRef();

  const carouselRef = useRef(null);
  const isScrolling = useRef(false);
  const targetScroll = useRef(0);
  const lastScrollLeft = useRef(0);


  useEffect(() => {

  }, [pricingOn]);

  const handlePricing = () => {
    setPricingOn(!pricingOn)
  }

  const smoothScroll = (target) => {
    isScrolling.current = true;
    targetScroll.current = target;

    const animateScroll = () => {
      if (!isScrolling.current) return;

      // Calculate the step for each frame to create an easing effect
      const step = (targetScroll.current - lastScrollLeft.current) * 0.1;
      if (Math.abs(step) < 0.5) {
        carouselRef.current.scrollLeft = targetScroll.current; // Snap to final position
        isScrolling.current = false; // Stop the animation
        return;
      }

      lastScrollLeft.current += step;
      carouselRef.current.scrollLeft = lastScrollLeft.current;
      requestAnimationFrame(animateScroll);
    };

    animateScroll();
  };

  const carouselData = [
    {
      imageSrc: carousel1,
      title: 'Novexa (May 2023)',
      description: 'Click to view full design',
      href: "https://www.figma.com/proto/hRyLxFm43IUW5TVgJqsAjm/Untitled?page-id=0%3A1&type=design&node-id=1-3&viewport=160%2C311%2C0.1&t=hXW7a6VacNNe5R9z-1&scaling=min-zoom&mode=design"
    },
    {
      imageSrc: carousel2,
      title: 'Discord Bot (July 2023)',
      description: 'Click to view full design',
      href: "https://www.figma.com/proto/hRyLxFm43IUW5TVgJqsAjm/Untitled?page-id=0%3A1&type=design&node-id=1-245&viewport=160%2C311%2C0.1&t=hXW7a6VacNNe5R9z-1&scaling=min-zoom&mode=design"
    },
    {
      imageSrc: carousel3,
      title: 'Discord Bot (August 2023)',
      description: 'Click to view full design',
      href: "https://www.figma.com/proto/hRyLxFm43IUW5TVgJqsAjm/Untitled?page-id=0%3A1&type=design&node-id=1-458&viewport=160%2C311%2C0.1&t=hXW7a6VacNNe5R9z-1&scaling=min-zoom&mode=design"
    },
    {
      imageSrc: carousel4,
      title: 'Sharemate (February 2024)',
      description: 'Click to view full design',
      href: "https://sharemateapp.com"
    },
    // ...other carousel items
  ];

  useEffect(() => {
    const handleGlobalWheel = (event) => {
      // Avoid handling the global wheel event if we're already scrolling the carousel
      if (isScrolling.current) return;
  
      event.preventDefault(); // Prevent window from scrolling
  
      if (!carouselRef.current) return;
  
      // Determine the direction and set the new active index
      const delta = event.deltaY > 0 ? 1 : -1;
      let newIndex = activeIndex + delta;
      const maxIndex = carouselData.length;
      newIndex = Math.max(0, Math.min(newIndex, maxIndex));
      
      // Update the active index
      setActiveIndex(newIndex);

      let scrollAmount;
  
      // Calculate and perform the smooth scroll
      if (window.innerWidth > 1400) {

        scrollAmount = 944;
      } else {
        scrollAmount = 738;
      }
      const newTargetScroll = newIndex * scrollAmount;
      smoothScroll(newTargetScroll);
    };
  
    // Attach the global wheel event listener
    if (window.innerWidth > 1000) {
      window.addEventListener('wheel', handleGlobalWheel, { passive: false });
    }
  
    return () => {
      // Clean up the global wheel event listener
      window.removeEventListener('wheel', handleGlobalWheel);
    };
  }, [activeIndex, window.innerWidth]); // Depend on activeIndex to update the wheel handler accordingly

  // useEffect(() => {
  //   const handleWheel = (event) => {
  //     event.preventDefault();
  //     if (isScrolling.current) return; // Ignore new wheel events if we're already scrolling

  //     const delta = event.deltaY > 0 ? 1 : -1;
  //     let newIndex = activeIndex + delta;
  //     const maxIndex = 5 - 1; // Assuming there are 5 items
  //     newIndex = Math.max(0, Math.min(newIndex, maxIndex)); // Clamp the value within the valid range
  //     setActiveIndex(newIndex); // Set the new active index

  //     const scrollAmount = 944; // Fixed amount to scroll
  //     const newTargetScroll = carouselRef.current.scrollLeft + delta * scrollAmount;

  //     smoothScroll(newTargetScroll);
  //   };

  //   const carouselEl = carouselRef.current;
  //   carouselEl.addEventListener('wheel', handleWheel, { passive: false });

  //   return () => {
  //     carouselEl.removeEventListener('wheel', handleWheel);
  //   };
  // }, [activeIndex]); // Add activeIndex to the dependency array



  const handleZoom = () => {
    const newZoomState = !zoom;
    setZoom(newZoomState); // Update the state
  };

  const handleUnprice = () => {
    window.location.href = "/"
  }

  


  useEffect(() => {

      // Setup the Three.js scene, camera, renderer
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      const renderer = new THREE.WebGLRenderer();
      renderer.setSize(window.innerWidth, window.innerHeight);
      mountRef.current.appendChild(renderer.domElement);

      // Create the particle geometry, material, and points
      const particlesGeometry = new THREE.BufferGeometry();
      const particlesCount = 5000;
      const posArray = new Float32Array(particlesCount * 3);

      for (let i = 0; i < particlesCount * 3; i++) {
        // Position them randomly
        posArray[i] = (Math.random() - 0.5) * 5;
      }

      particlesGeometry.setAttribute('position', new THREE.BufferAttribute(posArray, 3));

      const particlesMaterial = new THREE.PointsMaterial({
        size: 0.005,
        color: 'gray',
      });

      const particlesMesh = new THREE.Points(particlesGeometry, particlesMaterial);
      scene.add(particlesMesh);

      // Mouse movement
      document.addEventListener('mousemove', (event) => {
        // Update particle positions or other properties based on mouse movement
      });

      let frame = 0;
      const animate = () => {
        frame += 0.001;

        // Update particles
        particlesMesh.rotation.y = frame;

        // Render the scene
        renderer.render(scene, camera);

        requestAnimationFrame(animate);
      };

      animate();

      // Clean up on component unmount
      return () => {
        mountRef.current.removeChild(renderer.domElement);
        scene.remove(particlesMesh);
        particlesGeometry.dispose();
        particlesMaterial.dispose();
      };
  }, [image, pricingOn]);



  useEffect(() => {
    if (materialRef.current) {
      // materialRef.current.uniforms.u_zoom.value = zoom ? 1.0 : 0.0;
    }
  }, [zoom, pricingOn]);

  const calculateTimeLeft = () => {
    const targetDate = new Date('May 20, 2024 00:00:00').getTime();
    const now = new Date().getTime();
    const difference = targetDate - now;

    let timeLeft = '00:00:00:00';

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24)).toString().padStart(2, '0');
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24).toString().padStart(2, '0');
      const minutes = Math.floor((difference / 1000 / 60) % 60).toString().padStart(2, '0');
      const seconds = Math.floor((difference / 1000) % 60).toString().padStart(2, '0');
      timeLeft = `${days}:${hours}:${minutes}:${seconds}`;
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    // Set up a timer that runs every second
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear the timer
    return () => clearTimeout(timer);
  }, [timeLeft]);

  const [listTextItems, setListTextItems] = useState([
    { text: "Mobile & desktop versions (responsive design).", isActive: false },
    { text: "Created in code or any website designer of your choice.", isActive: false },
    { text: "Full ownership of code & design.", isActive: false },
    { text: "Custom SEO strategy to improve rankings and drive traffic.", isActive: false },
    { text: "CSS animations to enhance user experience.", isActive: false },
    { text: "Leading standards for your industry.", isActive: false },
    { text: "Special requests.", isActive: false },
    { text: "Revisions until 14 days after completion.", isActive: false },
    { text: "Money back guaranteed if not satisfied - no risk involved!", isActive: false },
    // ... other items
  ]);

  const [activeListIndex, setActiveListIndex] = useState(-1);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveListIndex((current) => {
        // This will increment the index by one or reset to zero if we've reached the end
        const nextIndex = current < listTextItems.length - 1 ? current + 1 : 0;

        // Update the state of each list item based on the nextIndex
        const updatedList = listTextItems.map((item, index) => ({
          ...item,
          isActive: index === nextIndex,
        }));

        setListTextItems(updatedList);

        return nextIndex;
      });
    }, 1000); // Time delay between each activation

    return () => {
      clearInterval(interval);
    };
  }, []);

  // To ensure the first index lights up when the component mounts,
  // you can activate it as soon as the component mounts like so:

  useEffect(() => {
    // Activate the first bullet point when the component mounts
    setListTextItems(listTextItems.map((item, index) => ({
      ...item,
      isActive: index === 0,
    })));
    setActiveListIndex(0); // Set the first bullet point as active
  }, []);

  return (
    <>
        <div className='pricing'>

          <div className='pricing-content'>
            <img className='logo' src={apolsor} onClick={() => {window.location.href = "/"}} />
            <div className='pricing-text'>

              <h1><span className='strike'>$699</span> $499 | Ultimate Package</h1>
              <h2>{timeLeft} | Limited time only</h2>

              <div className='list-container'>
                {listTextItems.map((item, index) => (
                  <div key={index} className='list-item'>
                    <div className={item.isActive ? 'bullet-active' : 'bullet-inactive'} />
                    <p>{item.text}</p>
                  </div>
                ))}
              </div>
              <br></br>
              <div className='start-here-border start-project' onClick={() => window.location.href = "https://apolsor.com/buy"}>

                <div className='start-here' >
                  Start Your Project Today
                </div>
              </div>
              <p className='contacts'>apolsoraseptu@gmail.com | x.com/apolsor | SMS: (330) 217 6369</p>
            </div>
          </div>

          <div className='particle-container'>
            <div className={`background-animation`} ref={mountRef} />

          </div>
        </div>
    </>
  );
};

export default Pricing;
